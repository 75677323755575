main {
    min-height: 80vh;
}

h1 {
    font-size: 1.8rem;
    padding: 1rem 0;
    color: #817d7d !important;
}

h2 {
    font-size: 1.4rem;
    margin-top: 0.5rem 0;
    padding-top: 1rem;
}

h3 {
    padding: 1rem;
}

.rating span {
    margin: 0.1rem;
}

/* Carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item-active {
    display: flex;
}

.carousel-caption {
    position: absolute;
    top: 0;
}

.carousel-caption h4 {
    color: #fff;
}

.carousel img {
    display: flex;
    height: 250px;
    padding: 20px;
    border-radius: 50%;
    margin: 40px auto;
}

.carousel a {
    margin: 0 auto;
}

.my-carousel {
    background-color: #f7c4b8 !important;
}

.cat-nav {
    padding: 10px 10px !important;
}

.cat-nav-items {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.cat-item {
    color: #817d7d !important;
    font-size: larger;
    cursor: pointer;
}

.cat-item:hover {
    color: #ff93ad !important;
}

.nav-item a {
    color: #54e8fc;
}

.search-button {
    color: #ff93ad !important;
    border-color: #ff93ad !important;
}

.search-button:hover {
    color: whitesmoke !important;
    border-color: whitesmoke !important;
    background-color: #ff93ad !important;
}

.link-color {
    color: #f58c72;
}

a:hover {
    color: #ff93ad !important;
}

.my-form {
    padding: 0.5rem 0.25rem !important;
}

.btn-primary {
    background-color: #ff93ad !important;
}
.btn-primary:hover {
    background-color: #ff93ad !important;
}

.navbar-brand {
    color: #ff93ad !important;
}

.navbar-text {
    padding: 0px !important;
}

span.page-link {
    background-color: #ff93ad !important;
}

.reg-login {
    margin-top: 1rem;
}

.reg-login a {
    color: #f58c72;
}
.alert a {
    color: #f58c72 !important;
}

.method-warning {
    color: red;
}

.aboutus-pic {
    width: 100%;
    height: auto;
    border: 5px solid #f58c72;
}
.about-p {
    margin-top: 15px;
}

.product-container {
    margin: 20px 0px 30px 0px;
}

@media (max-width: 400px) {
    h1 {
        font-size: 1.2rem !important;
    }
    h2 {
        font-size: 1.2rem !important;
    }
    .cat-nav {
        padding: 10px 10px !important;
    }
}

.bandeau {
    background-color: #ff93ad;
    text-align: center;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    height: 40px; /* Adjust as needed */
    white-space: nowrap;
    display: flex;
    align-items: center;
  

}
.bandeau .banner-body {
    position: relative;
    justify-content: center;
    align-items: center;
    display: inline-block;
  white-space: nowrap;
  will-change: transform;
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.bandeau img {
    position: relative;
    margin-right: 10px;
    width: 30px;
    animation-duration: 10s;
    animation-name: truck;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.bandeau p {
    margin: 0;
}
/* .bandeau .bandeau-new {
    position: relative;
    margin: 0 10px;
    animation-duration: 10s;
    animation-name: spanNew;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.bandeau .bandeau-text {
    position: relative;
    margin: 0 10px;
    animation-duration: 10s;
    animation-name: spanText;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
} */

.banner-button {
    position: absolute;
    /* top: 10px; */
    right: 0px;
    background-color: #ff93ad; /* Green */
    border: none;
    color: white;
    text-decoration: none;
    cursor: pointer;
  cursor: pointer;
  width: auto;
  height: 100%;
  padding-right: 10px;


}

.product-variant-images {
    cursor: pointer;
}

.product-variant-image-selected {
    border: 2px solid gray !important;
}


.original_price {
    position: relative;
    color: #817d7d;
    margin-right: 20px;
    float: left;
    font-size: 1rem;
    text-align: center;
  }
  .original_price:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid #dd0031!important;
    border-color: inherit;
  
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
  }

.card_text_div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px !important;
  }

  .dis-ribbon {
    position: absolute;
    right: 12px; top: 12px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
  }
  .dis-ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
  }
  .dis-ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }
  .dis-ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79A70A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }

  .message{
    margin-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
  }

.cart_open{
    display: block;
}

.cart_collapsed{
    display: none !important;
}


.header-cart_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}
.title-search{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sign-in-container{
    display: flex;
    justify-content: center;
    flex-direction: row;

}


.items:after{
    content:attr(value);
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background: #ff93ad;
    border-radius:60%;
    padding: 1px 5px;
    position:relative;
    left:-8px;
    top:-10px;
    opacity:1;
}

.adminOptionsMarkAsPaidFields{
    padding-bottom: 10px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.markPaidFields{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    max-width: 80%;
    width: 550px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .popup-modal-close {
    background-color: #c5bebe;
    border-radius: 50%;
    color: #fff;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    outline: none;
  }
  
  
  .popup-modal-close:hover {
    transform: scale(1.1);
  }
  
  
  .popup-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #000;
  }
  
  .popup-subtitle {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #000;
  }
  
  .popup-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: #000;
  }
  
  .popup-button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff93ad;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
  }

 
  .coupon-sent-heading {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .coupon-sent-message {
    font-size: 24px;
    color: #666;
    margin-bottom: 0;
  }
  
  .product-carousel {
    position: relative;
    width: 100%;
  }
  
  .carousel-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }
  
  .carousel-item1 {
    scroll-snap-align: start;
    flex: 0 0 auto;
    width: 25%;
    margin-right: 16px; /* Adjust as needed */
  }
  
  .carousel-image {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    object-fit: cover;
  }
  
  .carousel-title{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  }

  .carousel-prev,
  .carousel-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8); /* Adjust the background color as needed */
    border: none;
    cursor: pointer;
    outline: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Adjust the font size as needed */
    color: white; /* Adjust the button color as needed */
  }

  .carousel-prev:hover,
  .carousel-next:hover {
    background-color: rgba(0, 0, 0, 0.9); /* Adjust the hover background color as needed */
  }


.carousel-prev {
  left: -10px; /* Adjust the position as needed */
}

.carousel-next {
  right: -10px; /* Adjust the position as needed */
}

  .carousel-product-image {
    width: 70%;
  }
 
  .custom-carousel .carousel-control-prev-icon:before,
  .custom-carousel .carousel-control-next-icon:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-carousel .carousel-control-prev-icon:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid black;
  }
  
  .custom-carousel .carousel-control-next-icon:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid black;
  }
  
 
  

/* Media query for smaller screens */
@media (max-width: 576px) {
    .carousel-image{
        object-fit:fill;
        height: auto;
    }
    .carousel-item1 {
        flex: 0 0 80%;
      }
    .carousel-product-image {
        width: 100%;
      }
    .carousel-prev,
    .carousel-next {
      font-size: 16px; /* Adjust the font size for smaller screens */
    }
  }
  
  @media screen and (max-width: 768px) {
    .carousel-item1 {
        width: 55%;
      }
    
    .popup-modal-content {
      max-width: 100%;
      width: 90%;
      margin: 0 auto;
      padding: 20px;
    }
    
    .popup-modal-close {
      top: 5px;
      right: 5px;
      font-size: 2rem;
    }
    .popup-title {
        margin-top: 10px;
      }
  }
  
  
@media screen and (max-width: 992px) {
    .carousel-item1 {
        width: 35%;
      }
    
    .cart_open{
        display: none !important;
    }
    .cart_collapsed{
        display: block !important;
        margin-right: 10px;
    }

    .search_box{
        display: none !important;
        border: 1px solid rebeccapurple;
    }
    
}

@keyframes truck {
    0% {
        left: -40px;
        opacity: 0;
    }
    6%,
    100% {
        left: 0;
        opacity: 1;
    }
}
@keyframes spanNew {
    0%,
    5% {
        left: -30px;
        opacity: 0;
    }
    15% {
        left: 0;
        opacity: 1;
    }
}
@keyframes spanText {
    0%,
    10% {
        top: -20px;
        opacity: 0;
    }
    20% {
        top: 0;
        opacity: 1;
    }
}
@keyframes fadeUp {
    0% {
        opacity: 0;
    }
    10%,
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


